<template>
  <div>
    <childModal />
  </div>
</template>

<script>
import childModal from './components/childModal.vue'
export default {
  name: 'app-vue',
  components: {
    childModal,
  }
}
</script>
