import Vue from 'vue'
import Router from 'vue-router'
import AppVue from '../AppVue.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            component: AppVue,
        },
    ],
})
