
export const DA_DATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'

const createOptionsForDaData = (state, token) => {
    return {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            "Accept": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({query: state.cities[0] + ' ' + state.daData.address.fullStreet})
    }
}

export default (state, token) => createOptionsForDaData(state, token)
