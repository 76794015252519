const onlyUnique = (value, index, self) => self.indexOf(value) === index

export const mapVariantsToParameters = variants => {
    const keys = []
    let parameters = []
    const keysandvalues = []
    const result = []
    variants.forEach(variant => {
        parameters = variant.parameters
        parameters.forEach(parameter => {
            const { key, value } = parameter
            keysandvalues.push({ key, value })
            keys.push(key)
        })
    })
    const uniqueKeys = keys.filter(onlyUnique)

    uniqueKeys.forEach(uniqueKey => {
        const values = []
        keysandvalues.forEach(keyandvalue => {
            if (keyandvalue.key === uniqueKey) {
                values.push(keyandvalue.value)
            }
        })
        const uniqueValues = values.filter(onlyUnique)
        result.push({ key: uniqueKey, values: uniqueValues })
    })

    return result
}

export const filterVariantsByParameters = (variants, parameters) => (
    variants
        .filter(variant => {
            let flag = true
            parameters.forEach(parameter => {
                flag = false
                variant.parameters.forEach(ownParameter => {
                    if (parameter.key === ownParameter.key
                        && parameter.value === ownParameter.value) {
                        flag = true
                    }
                })
                if (!flag) return flag
            })
            return flag
        })
)
